
function Privacy() {
  return (
      <div class="pagebackground">
      <h1>Country Video Games PRIVACY POLICY</h1>
      <h2>
      Country Video Games is interested in your money, not your data.
      </h2>
      <p>
      Now, we don't want your money so bad that we're going to ship you some piece of doodoo spyware. 
      We don't want your money so bad that we'd t mine your stuff to 
      send it off to some AI sales guy.  
      We don't want it so bad we'll start showing bulldoodoo links 
      to other people's stuff and maybe worst of all to more piece of doodoo spyware.  
      </p>
      <p>
      We just don't collect your data and we don't ship spy stuff to collect it.
      </p>
      <p>
      Right now, our product is just a fun game, and we'd appreciate it if you could be 
      so kind as to give us a go.  We just want you to be entertained, with no strings attached.
      </p>
      <p>
      Thank you in advance for your kind consideration,
      </p>
      <p class="signature">
      Todd Bandrowsky
      </p>
      </div>
  );
}

export default Privacy;
