
function About() {
  return (
      <div class="pagebackground">
      <h1>
      About COUNTRY VIDEO GAMES
      </h1>
      <h2>We're in Kentucky</h2>
      <h2>With these people</h2>
      <ul>
       <li>Todd Bandrowsky</li>
      <li>Holly Hudnall</li>
      </ul>
      <h2>Making applications for Windows.</h2>      
      </div>
  );
}

export default About;
